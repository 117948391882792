import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Logo } from '../components/Logo';
import { SignupOrLogin } from '../components/SignupOrLogin';
import { LOGO_NAME, SOURCE_STORAGE_KEY } from '../constants';
import styles from '../styles/Home.module.css';
import Link from 'next/link';

function Login() {
  const router = useRouter();

  useEffect(() => {
    const referrer = router.query.via;
    localStorage?.setItem(
      SOURCE_STORAGE_KEY,
      referrer ? `rewardful-${referrer as string}` : router?.query?.utm_source as string,
    );
  }, [router?.query]);

  return (
    <div className={classNames(styles.page, styles.centered)}>
      <div className={styles.centeredContainer}>
        <div className={styles.logo}>
          <Logo height={50} />
        </div>
        <div className="title is-size-3 is-size-4-mobile has-text-weight-bold mb-4 has-text-centered">
          Welcome to
          {' '}
          {LOGO_NAME}
        </div>
        <div className="is-size-5 is-subtitle has-text-centered">
          Don&apos;t have an account?
          {' '}
          <Link
          href={{
            pathname: '/signup',
            query: router.query,
          }}>
          <span
            className="with-accent-text-color with-cursor-pointer"
          >
            <u>Create Account</u>
          </span></Link>
        </div>

        <SignupOrLogin login />

      </div>
    </div>
  );
}

export default Login;
